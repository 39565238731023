import React, { useLayoutEffect, useRef } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'

// components
import Footer from 'components/Footer'
import Header from 'components/Header'
import { createContext, useContext } from 'react'
import useQueryString from 'hooks/useQueryString'

export const HomeContext = createContext()

function Layout() {
    const productsRef = useRef(null)
    const productsRef1 = useRef(null)
    const productsRef2 = useRef(null)
    const coreValuesRef = useRef(null)
    const quotesRef = useRef(null)
    const contactRef = useRef(null)
    const location = useLocation()
    const { slug } = useParams()
    React.useLayoutEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [location])
    const queryString = useQueryString()
    const section = queryString.get('section')

    function scrollToTargetAdjusted(ref, value) {
        if (!ref?.current) return
        const element = ref.current
        const yOffset = -100
        const offsetPosition =
            element.getBoundingClientRect().top + window.pageYOffset + yOffset

        setTimeout(() => {
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            })
        }, 0)
    }

    function scrollToSection(value) {
        switch (value) {
            case 'products': {
                scrollToTargetAdjusted(productsRef, value)
                break
            }
            case 'core-values': {
                scrollToTargetAdjusted(coreValuesRef, value)
                break
            }
            case 'quotes': {
                scrollToTargetAdjusted(quotesRef, value)
                break
            }
            case 'contact': {
                scrollToTargetAdjusted(contactRef, value)
                break
            }
            default:
                break
        }
    }

    useLayoutEffect(() => {
        if (!section) return
        scrollToSection(section)
    }, [section])

    React.useEffect(() => {
        if (!slug) {
            document
                .getElementById('wrapper')
                .classList.remove('career-path-detail')
            return
        }
        document.getElementById('wrapper').classList.add('career-path-detail')
    }, [slug])
    return (
        <HomeContext.Provider
            value={{
                productsRef,
                coreValuesRef,
                quotesRef,
                contactRef,
                productsRef1,
                productsRef2,
            }}
        >
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
        </HomeContext.Provider>
    )
}

export const useHomeContext = () => useContext(HomeContext)
export default Layout
