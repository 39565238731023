import React from 'react'

// components
import TextFieldSimple from 'components/TextField/TextFieldSimple'
import TextAreaSimple from 'components/TextField/TextAreaSimple'

function FormApply() {
    return (
        <>
            <div className="mt-3">
                <TextFieldSimple label="Name" placeholder="Input name..." />
            </div>

            <div className="md:flex mt-3">
                <TextFieldSimple
                    label="Address"
                    placeholder="Input address..."
                />
                <div className="w-full mt-3 md:mt-0 md:ml-4">
                    <TextFieldSimple
                        label="Phone"
                        placeholder="Input phone..."
                    />
                </div>
            </div>

            <div className="mt-3">
                <TextFieldSimple
                    label="Email"
                    type="email"
                    placeholder="Input email..."
                />
            </div>

            <div className="mt-3">
                <TextFieldSimple
                    label="Link to CV"
                    placeholder="Input link..."
                />
            </div>

            <div className="mt-3">
                <TextAreaSimple
                    label="Message"
                    placeholder="Input message..."
                />
            </div>

            <div className="heading-9 mt-3">
                Please note that you can only apply for up to 2 positions in a
                given period.
            </div>
        </>
    )
}

export default FormApply
