import React from 'react'
import clsx from 'clsx'
import { Portal } from 'react-portal'

// assets
import ImgDesign from 'assets/career-path/design.png'
import ImgGame from 'assets/career-path/game.png'
import ImgQA from 'assets/career-path/qa.png'
import { ReactComponent as IconClose } from 'assets/svg/icon-close.svg'

// components
import { ButtonSimple } from 'components/Button'
import FormApply from 'components/FormApply/FormApply'

const positions = [
    {
        img: ImgDesign,
        location: 'Vietnam',
        level: 'Senior',
        title: 'Community Manager',
        href: 'community-manager',
    },
    {
        img: ImgGame,
        location: 'Remote',
        level: 'Mid Senior',
        title: 'Game Engineer',
        href: 'game-engineer',
    },
    {
        img: ImgQA,
        location: 'Ho Chi Minh city',
        level: 'Mid Senior',
        title: 'Mobile Engineer',
        href: 'mobile-engineer',
    },
    {
        img: ImgQA,
        location: 'Vietnam',
        level: 'Mid Senior',
        title: 'Backend Engineer',
        href: 'backend-engineer',
    },
]

function CareerPathDetail({
    title,
    level,
    summary,
    description = [],
    requirements = [],
}) {
    const [openApplyForm, setOpenApplyForm] = React.useState(false)

    return (
        <div className="milestone">
            <div className="container">
                <div className="careerDetail_wrapper">
                    <div className="careerDetail_left">
                        <h6 className="heading-7 text-color-content-secondary">
                            Vietnam
                        </h6>
                        <h4 className="heading-10-black text-color-content-primary mt-1">
                            {title}
                        </h4>
                        <h5 className="heading-6 text-color-content-accent-primary mt-1">
                            {level}
                        </h5>

                        <div className="careerDetail_info heading-8 text-color-content-secondary">
                            {summary}
                        </div>

                        <div className="careerDetail_content">
                            <h5>Job Description</h5>
                            <ul className="careerDetail_lead heading-8">
                                {description?.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="careerDetail_content">
                            <h5>Job Requirements</h5>
                            <ul className="careerDetail_lead heading-8">
                                {requirements?.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="careerDetail_content formApply_desktop">
                            <div className="careerDetail_form formApply">
                                <h5>Applying Information</h5>

                                <FormApply />
                            </div>

                            <div
                                className={clsx(
                                    'careerDetail_action mt-3 flex justify-end',
                                    openApplyForm && 'formApply_mobile'
                                )}
                            >
                                {openApplyForm && (
                                    <>
                                        <Portal>
                                            <div className="formApply_title">
                                                <span className="heading-8">
                                                    Applying Information
                                                </span>
                                                <div
                                                    className="formApply_title_iconClose"
                                                    onClick={() => {
                                                        setOpenApplyForm(false)
                                                        document.documentElement.style.overflow =
                                                            'auto'
                                                    }}
                                                >
                                                    <IconClose />
                                                </div>
                                            </div>
                                        </Portal>
                                        <div className="formChild">
                                            <FormApply />
                                        </div>
                                    </>
                                )}
                                <div className="btnApplyNow_desktop">
                                    <ButtonSimple
                                        text="Apply Now"
                                        minWidth="200px"
                                        onClick={() => {
                                            window.open(
                                                'mailto:contact@ambros.tech?subject=Job%20Application&body=Dear%20Ambros,%0D%0AI%20would%20like%20to%20apply%20for%20the%20position%20of%0D%0A'
                                                // '_self'
                                            )
                                        }}
                                    />
                                </div>
                                <div className="w-full btnApplyNow_mobile">
                                    <ButtonSimple
                                        text="Apply Now"
                                        minWidth="200px"
                                        onClick={() => {
                                            if (openApplyForm) {
                                                window.open(
                                                    'mailto:contact@ambros.tech?subject=Job%20Application&body=Dear%20Ambros,%0D%0AI%20would%20like%20to%20apply%20for%20the%20position%20of%0D%0A'
                                                    // '_self'
                                                )
                                            } else {
                                                setOpenApplyForm(true)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="careerDetail_right">
                        <h6 className="heading-7 text-color-content-primary">
                            Related Positions
                        </h6>

                        {positions.map((postion, index) => (
                            <div
                                key={index}
                                className="careerBox careerBox_item border-bottom round-0"
                                onClick={() => {}}
                            >
                                <div className="careerBox_container">
                                    <img
                                        src={postion.img}
                                        alt={postion.title}
                                        width={48}
                                    />
                                    <div className="careerBox_info">
                                        <h5 className="heading-9 text-color-content-secondary">
                                            {postion.location}
                                        </h5>
                                        <h4 className="heading-7-bold">
                                            {postion.title}
                                        </h4>
                                        <h6 className="heading-9 text-color-content-accent-primary">
                                            {postion.level}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CareerPathDetail
