import React from 'react'

function TextAreaSimple({ label, ...props }) {
    return (
        <div className="textFieldSimple">
            <label className="heading-9 text-color-content-primary">
                {label}
            </label>
            <textarea
                className="heading-8 text-color-content-secondary"
                {...props}
            ></textarea>
        </div>
    )
}

export default TextAreaSimple
