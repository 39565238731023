import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import Backend from 'i18next-http-backend'
//To load the translation files

i18n.on('languageChanged', function (lng) {
    localStorage.setItem('lng', lng)
})

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        react: {
            useSuspense: true,
        },
        // debug: process.env.NODE_ENV === 'production' ? false : true,
        debug: false,
        fallbackLng: 'en',
        fallbackOnEmpty: true,
        returnEmptyString: false,
        whitelist: ['en', 'vi'], //Array of abbrevations of the languages
        interpolation: {
            escapeValue: false,
        },
        ns: ['homepage', 'privacy', 'tos', 'market', 'news', 'news_detail'], //Names of the translation files
        backend: {
            expirationTime: 7 * 24 * 60 * 60 * 1000,
            loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`, //Path to the translation files
            requestOptions: {
                cache: 'no-store',
            },
            addPath: '',
        },
        detection: {
            order: ['localStorage'],
            lookupLocalStorage: 'lng',
            checkWhitelist: true,
        },
        saveMissing: true,
        saveMissingTo: 'all',
    })

export default i18n
