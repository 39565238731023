import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

// components
import Layout from 'components/Layout'
import Loading from 'components/Loading'
import Career from 'pages/Career/Career'
import CareerDetail from 'pages/Career/CareerDetail'
import GameEngineer from 'pages/Career/GameEngineer'
import MobileEngineer from 'pages/Career/MobileEngineer'
import CommunityManager from 'pages/Career/CommunityManager'
import BackendEngineer from 'pages/Career/BackendEngineer'

// pages
const HomePage = lazy(() => import('pages/Home/Home'))
const LifeAtAmbros = lazy(() => import('pages/LifeAtAmbros'))

function App() {
    return (
        <div id="wrapper" className="wrapper">
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<HomePage />} />
                        <Route path="/career-path" element={<Career />} />
                        <Route
                            path="/career-path/mobile-engineer"
                            element={<MobileEngineer />}
                        />
                        <Route
                            path="/career-path/game-engineer"
                            element={<GameEngineer />}
                        />
                        <Route
                            path="/career-path/community-manager"
                            element={<CommunityManager />}
                        />
                        <Route
                            path="/career-path/backend-engineer"
                            element={<BackendEngineer />}
                        />
                        <Route
                            path="/life-at-ambros"
                            element={<LifeAtAmbros />}
                        />
                        <Route path="/version" element={<p>0.0.1</p>} />
                        <Route path="/*" element={<Navigate to="/" />} />
                    </Route>
                </Routes>
            </Suspense>
        </div>
    )
}

export default App
