import { Link } from 'react-router-dom'
// assets
import { ReactComponent as Logo } from 'assets/images/logo/svg-logo-ambros.svg'
import { ReactComponent as LogoMobile } from 'assets/images/logo/svg-logo-ambros-mobile.svg'
import FrameFB from 'assets/images/social/FrameFB.svg'
import FrameTwitter from 'assets/images/social/FrameTwitter.svg'
import FrameMedium from 'assets/images/social/FrameMedium.svg'
import FrameYT from 'assets/images/social/FrameYT.svg'
import FrameTikTok from 'assets/images/social/FrameTikTok.svg'

function Footer() {
    return (
        <footer className="bg-always-dark-primary py-8">
            <div className="container">
                <div className="-mb-2 md:mb-0 footerMenu">
                    <div className="mb-6 footerMenu_left footerMenu_logo">
                        <div className="text-center footer_copyright mb-4">
                            <Logo className="mx-auto hidden lg:block mb-4" />
                            <LogoMobile className="mx-auto mb-4 lg:hidden" />
                            <p className="mb-1 text-sm text-link cursor-pointer">
                                <Link to="#">Terms of Service</Link>
                            </p>
                            <h6 className="text-sm text-always-light-primary">
                                © 2022 Ambros technology
                            </h6>
                        </div>
                    </div>

                    <div className="mx-auto footerMenu_right flex justify-center gap-x-1">
                        <img src={FrameFB} alt="" />
                        <img src={FrameTwitter} alt="" />
                        <img src={FrameMedium} alt="" />
                        <img src={FrameYT} alt="" />
                        <img src={FrameTikTok} alt="" />
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
