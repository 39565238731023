import React from 'react'
import CareerPathDetail from './CareerDetail'

function CommunityManager() {
    return (
        <CareerPathDetail
            title="Community Manager"
            level="Mid Senior"
            summary="Ambros Technology is an innovative tech company in Singapore. We have a great belief that cross-chain or multi-chain would be the next big thing in the crypto industry. Ambros's mission, therefore, is to pioneer the development of cutting-edge multi-chain solutions and infrastructure; and our primary value is to facilitate the digital ledger technology's adoption and efficient deployment in a multichain environment for multiple projects and companies."
            description={[
                'Moderate community sentiment on social media and key channels such as Discord, Twitter, Facebook, and Telegram',
                'Set up and implement social media and communication campaigns to align with marketing strategies',
                'Build immersive experiences through online community events like meetups, monthly community calls, and AMAs',
                'Create meaningful relationships across our community and source actionable insights to iterate our products',
                'Work across our functional teams and become an expert in community-driven blockchain solutions',
            ]}
            requirements={[
                'A strategic thinker, creative problem-solver and enjoy bringing your vision to life with actionable plans',
                'Strong capability to influence cross-function teams and agencies to on-time deliver tasks',
                'Proven experience in building welcoming and inclusive communities, and a passion for community-driven projects',
                'Outstanding cross-cultural and creative communication skills and an innate ability to adapt messaging to different personas and channels',
                'Experience engaging a wide community audience through Discord and onboarding people into how to contribute effectively',
                'And ideally, a deep understanding of crypto culture and relationships across the ecosystem',
            ]}
        />
    )
}

export default CommunityManager
