import React from 'react'
import clsx from 'clsx'
import { Portal } from 'react-portal'
import CareerPathDetail from './CareerDetail'

function GameEngineer() {
    const [openApplyForm, setOpenApplyForm] = React.useState(false)

    return (
        <CareerPathDetail
            title="Game Engineer"
            level="Mid Senior"
            summary="Ambros Technology is an innovative tech company in Singapore. We have a great belief that cross-chain or multi-chain would be the next big thing in the crypto industry. Ambros's mission, therefore, is to pioneer the development of cutting-edge multi-chain solutions and infrastructure; and our primary value is to facilitate the digital ledger technology's adoption and efficient deployment in a multichain environment for multiple projects and companies."
            description={[
                'Align with project managers and other team members to deliver fast and high quality features;',
                'Craft code that meets our internal standards for style, maintainability, and best practices for a high-scale web environment. Maintain and advocate for these standards through code review;',
                'Consistently ship small features and improvements with minimal guidance and support from other team members. Collaborate with the team on larger projects;',
                'Help improve the overall experience of our product through improving the quality of the Frontend features both under your responsibilities and features that benefit other groups;',
                'Help identify areas of improvements in the code base and help contribute to make it better.',
            ]}
            requirements={[
                'Professional experience with PixiJS or another Web game framework;',
                'Professional experience with building real-time applications or multiplayer games;',
                'Experience with building games in a professional setting or as a hobby;',
                'A solid understanding in core web and browser concepts (eg. how the browser parses and constructs a web page);',
                'Experience with performance and optimization problems and a demonstrated	ability to both diagnose and prevent these problems;',
                'Comfort working in a highly agile, intensely interactive software development process;',
                'Ability to thrive in a fully remote organization',
            ]}
        />
    )
}

export default GameEngineer
