import React, { useCallback, useMemo, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
// assets
import { ReactComponent as Logo } from 'assets/images/logo/svg-logo-ambros.svg'
import { ReactComponent as DarkControl } from 'assets/svg/DarkControl.svg'
import { ReactComponent as LightControl } from 'assets/svg/LightControl.svg'
import { useThemeContext } from 'context/ThemeContext'
import useQueryString from 'hooks/useQueryString'
import { useClickAway } from 'react-use'
import { useHomeContext } from 'components/Layout/Layout'

// components

function Header() {
    const { palette, handleChangePalette } = useThemeContext()
    const { coreValuesRef, productsRef1, productsRef2 } = useHomeContext()
    function handlePalette() {
        const newPalette = palette === 'light' ? 'dart' : 'light'
        handleChangePalette(newPalette)
    }
    const [isOpen, setIsOpen] = useState(false)
    const headerRef = React.useRef()
    const location = useLocation()
    const queryString = useQueryString()
    const section = queryString.get('section')

    React.useEffect(() => {
        function handleAnimation(
            ref,
            scrollTop,
            classList = '',
            yOffset = 120
        ) {
            if (!ref?.current) return
            const element = ref.current
            const offsetPosition = element.getBoundingClientRect().top - yOffset

            if (offsetPosition <= 0) {
                if (!element.classList.contains(classList))
                    element.classList.add(classList)
            }
        }
        function handleScroll() {
            const scrollTop = document.documentElement.scrollTop
            // header
            if (scrollTop > 44 && scrollTop < 1000) {
                headerRef.current.classList.add('header-bgDark')
            }
            // reset
            if (scrollTop < 43) {
                headerRef.current.classList.remove('header-bgDark')
            }
            // animation
            handleAnimation(productsRef1, scrollTop, 'product-animation-1', 650)
            handleAnimation(productsRef2, scrollTop, 'product-animation-2', 600)
            handleAnimation(
                coreValuesRef,
                scrollTop,
                'core-value-animation',
                750
            )
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    React.useEffect(() => {
        const scrollTop = document.documentElement.scrollTop
        // header
        if (scrollTop > 44) {
            headerRef.current.classList.add('header-bgDark')
        }
        // reset
        if (scrollTop < 43) {
            headerRef.current.classList.remove('header-bgDark')
        }
    }, [palette])

    const handleCloseMenu = useCallback(() => {
        if (!isOpen) return
        setIsOpen(false)
    }, [isOpen])

    const isHomePage = useMemo(() => {
        return (
            location.pathname === '/' ||
            location.pathname === '' ||
            location.pathname === '/home'
        )
    }, [location])

    useClickAway(
        headerRef,
        () => {
            if (isOpen) {
                setIsOpen(false)
            }
        },
        ['mousedown']
    )
    const isMenuActive = (isActive, sectionName, pathName) => {
        const baseClass =
            'mr-4 font-normal text-content-primary hover:text-teal'
        if (
            isActive &&
            section === sectionName &&
            pathName === location.pathname
        ) {
            return `${baseClass} active`
        }

        return baseClass
    }

    return (
        <div
            ref={headerRef}
            className={`header_stage ${
                isHomePage ? 'home-page-header' : 'not-home-page'
            } ${palette === 'light' ? 'light' : 'dark'}`}
        >
            <header className={`header ${isOpen ? 'header_mobile' : ''}`}>
                <div className="container">
                    <div className="bg-transparent">
                        <div className="flex w-full header-container">
                            <div className="mr-8 header_logo">
                                <Link to="/" className="flex items-center">
                                    <Logo />
                                </Link>
                            </div>
                            <nav className="flex items-center w-full header_menu_nav">
                                <ul className="flex items-center -ml-2">
                                    <li onClick={handleCloseMenu}>
                                        <NavLink
                                            to="/"
                                            className={({ isActive }) =>
                                                isMenuActive(
                                                    isActive,
                                                    null,
                                                    '/'
                                                )
                                            }
                                        >
                                            Home
                                        </NavLink>
                                    </li>
                                    <li onClick={handleCloseMenu}>
                                        <NavLink
                                            to="/?section=products"
                                            className={({ isActive }) =>
                                                isMenuActive(
                                                    isActive,
                                                    'products',
                                                    '/'
                                                )
                                            }
                                        >
                                            Products
                                        </NavLink>
                                    </li>
                                    <li onClick={handleCloseMenu}>
                                        <NavLink
                                            to="/?section=core-values"
                                            className={({ isActive }) =>
                                                isMenuActive(
                                                    isActive,
                                                    'core-values',
                                                    '/'
                                                )
                                            }
                                        >
                                            Core Values
                                        </NavLink>
                                    </li>
                                    <li onClick={handleCloseMenu}>
                                        <NavLink
                                            to="/?section=quotes"
                                            className={({ isActive }) =>
                                                isMenuActive(
                                                    isActive,
                                                    'quotes',
                                                    '/'
                                                )
                                            }
                                        >
                                            Press
                                        </NavLink>
                                    </li>
                                    <div className="split mr-4 "></div>
                                    <li onClick={handleCloseMenu}>
                                        <NavLink
                                            to="/life-at-ambros"
                                            className="mr-4 font-normal text-content-primary hover:text-teal"
                                        >
                                            Life at Ambros
                                        </NavLink>
                                    </li>
                                    <li onClick={handleCloseMenu}>
                                        <NavLink
                                            to="/career-path"
                                            className="mr-4 font-normal text-content-primary hover:text-teal"
                                        >
                                            Career
                                        </NavLink>
                                    </li>
                                    <li onClick={handleCloseMenu}>
                                        <NavLink
                                            to="/?section=contact"
                                            className={({ isActive }) =>
                                                isMenuActive(
                                                    isActive,
                                                    'contact',
                                                    '/'
                                                )
                                            }
                                        >
                                            Contact
                                        </NavLink>
                                    </li>
                                </ul>
                            </nav>
                            <div className="ml-auto header_theme">
                                <div className="flex items-center">
                                    <div className="cursor-pointer theme-box">
                                        <div className="bg-secondary flex p-[2px] rounded-lg cursor-pointer themeControl">
                                            <div
                                                className={`themeControl_box ${
                                                    palette === 'light'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() => handlePalette()}
                                            >
                                                <LightControl />
                                            </div>
                                            <div
                                                className={`themeControl_box ${
                                                    palette === 'dark'
                                                        ? 'active'
                                                        : ''
                                                }`}
                                                onClick={() => handlePalette()}
                                            >
                                                <DarkControl className="dark-control-theme" />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`header_iconMenu hidden ${
                                            isOpen ? 'is-show' : ''
                                        }`}
                                        onClick={() =>
                                            setIsOpen((state) => !state)
                                        }
                                    >
                                        <div className="header_iconMenu_line"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header
