import React from 'react'
import CareerPathDetail from './CareerDetail'

function MobileEngineer() {
    return (
        <CareerPathDetail
            title="Mobile Engineer"
            level="Mid Senior"
            summary="Ambros Technology is an innovative tech company in Singapore. We have a great belief that cross-chain or multi-chain would be the next big thing in the crypto industry. Ambros's mission, therefore, is to pioneer the development of cutting-edge multi-chain solutions and infrastructure; and our primary value is to facilitate the digital ledger technology's adoption and efficient deployment in a multichain environment for multiple projects and companies."
            description={[
                'Develop hybrid mobile applications using React Native and the latest iOS/Android programing techniques',
                'Contribute to all phases of the application development life cycle (concept, design, release test, support)',
                'Work with product owners and UI/UX teams to conceptualize and design mobile applications',
                'Translate UI/UX designs and business requirements into high quality code',
                'Understand the product, constantly optimize for performance, identify and fix problems, improve stability and user experience',
                'Write detailed documentations for existing and new applications',
                'Stay updated on modern mobile development frameworks & technologies, best-practices, trends and trade-offs',
            ]}
            requirements={[
                'Minimum 1 to 5 years of hands-on mobile development experience',
                'Experienced candidates will be considered for Lead roles',
                'Working knowledge in developing iOS/Android or React Native applications using Swift/Java/Kotlin/Javascript',
                'Experience building maintainable and testable code bases, including API design and unit testing techniques',
                'Familiarity with DevOps concepts such as CI/CD, GIT, Confluence, Bitbucket, etc',
                'Experience working in scrum squads, have the ability to work effectively in scrum teams',
                'Good understanding of Design and Architecture Patterns',
                'Excellent working attitude, problem solving and critical thinking skills',
                'Passionate about exploring new technologies & upgrading skillsets',
            ]}
        />
    )
}

export default MobileEngineer
