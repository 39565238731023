import React from 'react'
import CareerPathDetail from './CareerDetail'

function BackendEngineer() {
    return (
        <CareerPathDetail
            title="Backend Engineer"
            level="Senior"
            summary="Ambros Technology is an innovative tech company in Singapore. We have a great belief that cross-chain or multi-chain would be the next big thing in the crypto industry. Ambros's mission, therefore, is to pioneer the development of cutting-edge multi-chain solutions and infrastructure; and our primary value is to facilitate the digital ledger technology's adoption and efficient deployment in a multichain environment for multiple projects and companies."
            description={[
                'Build extensible and scalable software services in Python and Golang to serve our core services',
                'Engage in and improve the whole lifecycle of service, from inception and design, through to deployment, operation and refinement',
                'Increase system reliability through code optimization, improved monitoring and early problem detection, security compliance, and improved change management practices',
                'Continually improve your and your colleagues’ code through peer review, pair programming and knowledge sharing',
                'Applying standard methodologies to satisfy our priorities of code excellence and high availability software',
            ]}
            requirements={[
                'At least 5 years of professional experience building scalable services using dynamic languages such as Python or Golang;',
                'Proven experience working with cloud infrastructure technologies, such as GCP or AWS',
                'Proficiency in relational databases, such as MySQL or Postgres',
                'Solid knowledge of OOP and software design patterns, you should be able to design software systems that are extensible, reusable and meet desired architectural objectives',
                'Demonstrated capacity to clearly and concisely communicate about complex technical, architectural, and/or organizational problems and propose thorough iterative solutions',
                'Experience with performance and optimization problems and a demonstrated ability to both diagnose and prevent these problems',
                'Comfort working in a highly agile, intensely interactive software development process;',
                'Experience working with continuous integration and continuous deployment systems (GitHub Actions, Jenkins)',
                'Ability to thrive in a fully remote organization',
            ]}
        />
    )
}

export default BackendEngineer
