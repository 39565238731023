import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
    createSearchParams,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom'

export const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [palette, setPalette] = useState(
        window.sessionStorage.getItem('palette') || 'dark'
    )
    function handleChangePalette() {
        const newPattle = palette === 'dark' ? 'light' : 'dark'
        window.sessionStorage.setItem('palette', newPattle)
        setPalette(newPattle)
    }

    useEffect(() => {
        if (palette === 'light') {
            document.documentElement.setAttribute('data-theme', 'light')
        } else {
            document.documentElement.removeAttribute('data-theme')
        }
    }, [palette])

    const themeContextValue = {
        palette,
        handleChangePalette,
    }

    return (
        <ThemeContext.Provider value={themeContextValue}>
            {children}
        </ThemeContext.Provider>
    )
}

export const useThemeContext = () => useContext(ThemeContext)
